import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import SearchItem from 'Components/searchItem/index.jsx';
import { createSelectedVehicleSelectionSelector } from 'Core/selectors/fitmentSearch/index.js';
import { extractedColorsSelector, productDataSelector } from 'Core/selectors/product.ts';
import { SearchItemData, SearchItemDataCollection } from 'Models/index.ts';
import productConfig from 'Models/uiConfig/productConfig.js';
import { getUriFromRequest } from 'Modules/converter/index.js';
import { search, recommend } from 'Modules/serverApi/index.js';

import type { FC } from 'react';
import type { RepeaterFunctionInvoker, TemplateFunction } from 'Components/types.ts';
import type { FacetRequest } from 'Models/index.ts';
import type { ServerModel } from 'Modules/serverApi/types.ts';

type Params = {
  widgetName: string;
  items: RepeaterFunctionInvoker<SearchItemDataCollection>;
  searchLink: string;
};

type Props = {
  template: TemplateFunction<Params>;
  name: string;
  count?: number;
  requestExtra?: Record<string, unknown>;
  sort?: string;
  useManualItems?: true;
  useRecEngine?: true;
  getSelection?: (itemData: ServerModel.SearchItem) => FacetRequest[] | undefined;
  onItemsRendered?: () => void;
};

const RelatedItems: FC<Props> = ({
  template,
  name: widgetName,
  count,
  requestExtra,
  sort,
  useManualItems,
  useRecEngine,
  getSelection,
  onItemsRendered,
}) => {
  const {
    getImageToExtractColors,
    localItemId,
  }: { getImageToExtractColors?: () => void; localItemId?: string } = productConfig;

  const productData = useSelector(productDataSelector);
  const vehicleSelection = useSelector(createSelectedVehicleSelectionSelector);

  const extractedColors = useSelector(extractedColorsSelector);

  const [customRequestSelection, setCustomRequestSelection] = useState<FacetRequest[]>([]);
  const [relatedItems, setRelatedItems] = useState<SearchItemDataCollection>();

  const handleResponse = useCallback(
    (response: ServerModel.SearchResponse) => {
      setRelatedItems(
        new SearchItemDataCollection(
          response.Items.filter(
            (item) => `${SearchItemData.getFromRaw(item, 'id')}` !== `${localItemId}`,
          ).slice(0, count),
          'related',
        ),
      );
    },
    [count, localItemId],
  );

  useEffect(() => {
    if (useRecEngine && localItemId && count) {
      recommend({ itemId: localItemId, count }).then((response: ServerModel.SearchResponse) =>
        handleResponse(response),
      );
    }
  }, [count, localItemId, handleResponse, useRecEngine]);

  useEffect(() => {
    // send a request without facet selection to make a response on the server, e.g. in plugins
    if (useManualItems && count) {
      search({
        selection: vehicleSelection,
        pageSize: count,
        extra: { itemId: localItemId, mode: 'RelatedItems', ...(requestExtra || {}) },
      }).then((response: ServerModel.SearchResponse) => handleResponse(response));
    }
  }, [count, localItemId, handleResponse, useManualItems, vehicleSelection, requestExtra]);

  useEffect(() => {
    if (
      productData &&
      Object.keys(productData).length &&
      getSelection &&
      count &&
      // wait until the extracted color response is received if getImageToExtractColors() is provided
      (!getImageToExtractColors || extractedColors)
    ) {
      let customSelection = getSelection(productData);

      if (!Array.isArray(customSelection)) {
        // don't let unknown things be added to the request
        customSelection = [];
      } else if (customSelection.length) {
        setCustomRequestSelection(customSelection);
      }

      const colorsSelection = extractedColors ? extractedColors.toFacetValues('_Color') : [];
      const selection = [...customSelection, ...vehicleSelection, ...colorsSelection];

      search({
        selection,
        pageSize: count,
        sort,
        extra: { itemId: localItemId, ...(requestExtra || {}) },
      }).then((response: ServerModel.SearchResponse) => handleResponse(response));
    }
  }, [
    count,
    getSelection,
    localItemId,
    productData,
    handleResponse,
    sort,
    vehicleSelection,
    getImageToExtractColors,
    extractedColors,
    requestExtra,
  ]);

  useEffect(() => {
    if (relatedItems?.length) {
      onItemsRendered?.();
    }
  }, [relatedItems, onItemsRendered]);

  if (!relatedItems?.length) {
    return null;
  }

  const { href: searchLink } = getUriFromRequest(
    { selection: [...customRequestSelection, ...vehicleSelection].filter(Boolean) },
    { goToSearchPage: true },
  );

  const items = relatedItems
    ? (relatedItems.repeaterComponents(SearchItem) as RepeaterFunctionInvoker<SearchItemDataCollection>)
    : [];

  return template.call({ widgetName, items, searchLink });
};

export default RelatedItems;
